<template>
  <PageContent class="calendar-content">
    <CalendarView
      v-model:selectedDay="selectedDay"
      v-model:navigatedDate="navigatedDate"
      :navigatedDate="navigatedDate"
      :timeSlots="timeSlots"
      :todayCalendarEvents="todayCalendarEvents"
      :userType="UserType.Buyer"
      :upcomingEventDates="upcomingEventDates"
      @update:selectedDay="selectedDayChange"
      @eventTileClick="eventTileClicked"
    />
  </PageContent>
</template>

<script lang="ts">
import { UserCalendarEventDto } from '@bd/api/common'
import { CommonUserCalendarEventsParams } from '@bd/api/common/types/calendar'
import { useAppStore } from '@bd/client/store'
import {
  CalendarView,
  PageContent,
  useCalendarLocalState,
  UserType,
} from '@bd/components'
import { LocalIsoDate } from '@bd/helpers'
import { computed, defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    PageContent,
    CalendarView,
  },
  setup() {
    const store = useAppStore()
    const router = useRouter()
    const upcomingEventDates = computed(
      () => store.state.calendar?.upcomingEventDates,
    )

    const {
      selectedDay,
      navigatedDate,
      onSelectedDayChange,
    } = useCalendarLocalState(store.state.calendar?.selectedDate)

    const timeSlots = computed(() => store.state.staticData?.timeSlots ?? [])
    const todayCalendarEvents = computed(
      () => store.state.calendar?.todayEvents ?? [],
    )

    const fetchTodayEvents = async () => {
      const params: CommonUserCalendarEventsParams = {
        date: selectedDay.value,
      }
      await store.dispatch('calendar/userCalendarEvents', params)
    }

    const selectedDayChange = (day: LocalIsoDate) => {
      if (onSelectedDayChange(day)) {
        store.commit('calendar/SET_SELECTED_DATE', day)
        fetchTodayEvents()
      }
    }

    const eventTileClicked = (event: UserCalendarEventDto) => {
      router.push({
        path: `/calendar/event/${event.userCalendarEventId}`,
      })
    }

    const fetchUpcomingEventDates = async () => {
      await store.dispatch('calendar/getUpcomingEventDates')
    }

    onMounted(() => {
      fetchTodayEvents()
      fetchUpcomingEventDates()
    })

    return {
      timeSlots,
      selectedDay,
      selectedDayChange,
      todayCalendarEvents,
      navigatedDate,
      eventTileClicked,
      UserType,
      upcomingEventDates,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.calendar-content) {
  background-color: $alabaster;
  flex-direction: row;
  @include breakpoint-down(md) {
    height: calc(100vh + #{$mobile-navigation-height}) !important;
  }
}
</style>
