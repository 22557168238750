
import { UserCalendarEventDto } from '@bd/api/common'
import { CommonUserCalendarEventsParams } from '@bd/api/common/types/calendar'
import { useAppStore } from '@bd/client/store'
import {
  CalendarView,
  PageContent,
  useCalendarLocalState,
  UserType,
} from '@bd/components'
import { LocalIsoDate } from '@bd/helpers'
import { computed, defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    PageContent,
    CalendarView,
  },
  setup() {
    const store = useAppStore()
    const router = useRouter()
    const upcomingEventDates = computed(
      () => store.state.calendar?.upcomingEventDates,
    )

    const {
      selectedDay,
      navigatedDate,
      onSelectedDayChange,
    } = useCalendarLocalState(store.state.calendar?.selectedDate)

    const timeSlots = computed(() => store.state.staticData?.timeSlots ?? [])
    const todayCalendarEvents = computed(
      () => store.state.calendar?.todayEvents ?? [],
    )

    const fetchTodayEvents = async () => {
      const params: CommonUserCalendarEventsParams = {
        date: selectedDay.value,
      }
      await store.dispatch('calendar/userCalendarEvents', params)
    }

    const selectedDayChange = (day: LocalIsoDate) => {
      if (onSelectedDayChange(day)) {
        store.commit('calendar/SET_SELECTED_DATE', day)
        fetchTodayEvents()
      }
    }

    const eventTileClicked = (event: UserCalendarEventDto) => {
      router.push({
        path: `/calendar/event/${event.userCalendarEventId}`,
      })
    }

    const fetchUpcomingEventDates = async () => {
      await store.dispatch('calendar/getUpcomingEventDates')
    }

    onMounted(() => {
      fetchTodayEvents()
      fetchUpcomingEventDates()
    })

    return {
      timeSlots,
      selectedDay,
      selectedDayChange,
      todayCalendarEvents,
      navigatedDate,
      eventTileClicked,
      UserType,
      upcomingEventDates,
    }
  },
})
